

// import React, { useState, useEffect } from 'react';
// import Data from './components/Data';
// import Header from './components/Header';
// import Sidebar from './components/Sidebar';
// import { auth } from './firebase';
// import styled from 'styled-components';
// import SignIn from './SignIn';

// // Styled component for loading indicator
// const LoadingWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background-color: lightgrey;
//   font-size: 24px;
// `;

// function App() {
//   const [user, setUser] = useState(null);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged(user => {
//       setUser(user);
//       setLoading(false); // Set loading to false once auth state is determined
//     });
//     return () => unsubscribe();
//   }, []);

//   const signOut = () => {
//     auth.signOut().then(() => {
//       setUser(null);
//       // Optional: You might want to redirect to a different page or show a message here
//     }).catch(error => {
//       alert(error.message);
//     });
//   };

//   const handleSearch = (query) => {
//     setSearchQuery(query);
//   };

//   if (loading) {
//     return <LoadingWrapper>Loading...</LoadingWrapper>; // Display a loading indicator while checking auth state
//   }

//   return (
//     <>
//       {user ? (
//         <>
//           <Header photoURL={user.photoURL} email={user.email} onSearch={handleSearch} onSignOut={signOut} />
//           <div className="App" style={{ display: 'flex' }}>
//             {/* <Sidebar /> */}
//             <Data searchQuery={searchQuery} />
//           </div>
//         </>
//       ) : (
//         <SignIn onSignIn={() => auth.onAuthStateChanged(setUser)} />
//       )}
//     </>
//   );
// }

// export default App;





















import React, { useState, useEffect } from 'react';
import Data from './components/Data';
import Header from './components/Header';
import { auth } from './firebase';
import styled from 'styled-components';
import SignIn from './SignIn';
import Footer from './components/Footer';

// Styled component for loading indicator
const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: lightgrey;
  font-size: 24px;
`;

function App() {
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentFolderId, setCurrentFolderId] = useState(null); // State to manage the current folder

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
      setLoading(false); // Set loading to false once auth state is determined
    });
    return () => unsubscribe();
  }, []);

  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      // Optional: You might want to redirect to a different page or show a message here
    }).catch(error => {
      alert(error.message);
    });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleLogoClick = () => {
    setCurrentFolderId(null); // Reset to home or main page
  };

  if (loading) {
    return <LoadingWrapper>Loading...</LoadingWrapper>; // Display a loading indicator while checking auth state
  }

  return (
    <>
      {user ? (
        <>
          <Header
            photoURL={user.photoURL}
            email={user.email}
            onSearch={handleSearch}
            onSignOut={signOut}
            onLogoClick={handleLogoClick} // Pass the navigateToHome function to Header
          />
          <div className="App" style={{ display: 'flex' }}>
            {/* <Sidebar /> */}
            <Data searchQuery={searchQuery} currentFolderId={currentFolderId} setCurrentFolderId={setCurrentFolderId}  />
            <Footer/>
          </div>
        </>
      ) : (
        <SignIn onSignIn={() => auth.onAuthStateChanged(setUser)} />
      )}
    </>
  );
}

export default App;

