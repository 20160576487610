// import React from 'react';
// import styled from 'styled-components';


// const StyledTitle = styled.h3`
//   text-align: center;               
//   font-family: 'Anton', sans-serif; 
//   font-size: 36px;                 
//   font-weight: normal;              
//     .text-gradient {
//   background: linear-gradient(to right, rgb(255, 0, 150), rgb(0, 204, 255)); /* Gradient color */
//   -webkit-background-clip: text; 
//   -webkit-text-fill-color: transparent;
// }              
//   color: #333;                      
// `;


// const StyledWelcomeMessage = styled.h4`
//   text-align: center;           
//   font-family: 'Anton', sans-serif;
//   font-size: 14px;                 
//   font-weight: 300;                                 
//   color: #333;                      
// `;

// function Welcome() {
//   return (
//     <>
//       <StyledTitle>WELCOME TO SPAN DUMP </StyledTitle>
//       <StyledWelcomeMessage>
      
//         THE SMART SOLUTION FOR ALL YOUR FILE BACKUP NEEDS
//       </StyledWelcomeMessage>
//     </>
//   );
// }

// export default Welcome;


import React from 'react';
import styled from 'styled-components';

// Styled component for the h3 element with gradient text
const StyledTitle = styled.h3`
  text-align: center;               
  font-family: 'Anton', sans-serif; 
  font-size: 40px;                 
  font-weight: normal;              
  background: linear-gradient(to right, rgb(255, 0, 150), rgb(0, 204, 255)); /* Gradient color */
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent; /* Makes text transparent */
  color: transparent;                /* Prevents fallback color from showing */
  

  @media (max-width: 768px) {
    font-size: 30px; /* Adjust font size for tablet */
  }

  @media (max-width: 480px) {
    font-size: 16px; /* Adjust font size for mobile */
  }
`;

const StyledWelcomeMessage = styled.h4`
  text-align: center;           
  font-size: 12px;                 
  font-weight: 400;                                 
  color: black; 
  background: linear-gradient(to right, black, black);
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent; 
  color: transparent;               
   margin-bottom: 2px; 
  @media (max-width: 768px) {
    font-size: 12px; /* Adjust font size for tablet */
  } 
  @media (max-width: 480px) {
    font-size: 8px; /* Adjust font size for mobile */
  }
`;

function Welcome() {
  return (
    <>
      <StyledTitle>
        WELCOME TO THE DIGITAL TEAM'S CENTRAL REPOSITORY
      </StyledTitle>
      <StyledWelcomeMessage>
        PLEASE CONSIDER CAREFULLY WHAT YOU UPLOAD HERE; WHILE THE ENVIRONMENT MIGHT NOT BE HARMED, THE BANDWIDTH CERTAINLY COULD
      </StyledWelcomeMessage>
    </>
  );
}

export default Welcome;

