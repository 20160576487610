
// import styled from 'styled-components';
// import { Avatar,Tooltip } from '@material-ui/core';
// import { useState } from 'react';
// import { ExitToApp } from '@material-ui/icons'; // Import a logout icon from Material-UI

// const HeaderContainer = styled.div`
//     display: flex;
//     align-items: center;
//     padding: 5px 20px;
//     width:1140px;
//     height: 60px;
//     background-color: #1925FF;
//     border-bottom: 1px solid lightgray;
//     color: white;
//     justify-content: space-between;

//     /* Default width for extra small screens */
//     width: 100%; /* Adjust as needed for mobile */

//     @media (min-width: 480px) {
//         width: 80%; /* Adjust for medium screens */
//     }

//     @media (min-width: 768px) {
//         width: 60%; /* Adjust for large screens */
//     }

//     @media (min-width: 1024px) {
//         width: 1140px; /* Adjust for extra large screens, if needed */
//     }
// `;


// const HeaderLogo = styled.div`
//     display: flex;
//     align-items: center;
//     img {
//         width: 80px;
      
//     }
//         @media (min-width: 1024px) {
//             width: 200px; /* Size for extra large screens, if needed */
//         }
//     span {
//         font-size: 22px;
//         margin-left: 10px;
//         color: white;
//     }
// `;



// const HeaderIcons = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
    
//     span {
//         display: flex;
//         align-items: center;
//         margin-right: 20px; /* Increased spacing between email and profile avatar */
//         color: white;
//     }
    
//     svg.MuiSvgIcon-root {
//         margin: 0px 20px; /* Adjusted margin */
//         color: white;
//         cursor: pointer; /* Change cursor to pointer for clickable icons */
//     }
// `;

// const Header = ({ photoURL, email, onSearch, onSignOut }) => {
//     const [searchQuery, setSearchQuery] = useState('');

//     const handleInputChange = (e) => {
//         const query = e.target.value;
//         setSearchQuery(query);
//         onSearch(query); // Pass the search query up to the parent component
//     };

//     return (
//         <HeaderContainer>
//             <div style={{width:'1140px'}}> 
//             <HeaderLogo>
//                 <img src="Span logo (1).png" alt="Span Drive" />
               
//             </HeaderLogo>

//             {/* <HeaderSearch>
//                 <input
//                     type="text"
//                     placeholder="Search"
//                     value={searchQuery}
//                     onChange={handleInputChange}
//                 />
//             </HeaderSearch> */}

//             <HeaderIcons>
//                 <span>{email}</span>
//                 <Avatar src={photoURL} />
//                 <Tooltip title="Logout" arrow>
//                     <span>
//                         <ExitToApp onClick={onSignOut} /> {/* Logout icon */}
//                     </span>
//                 </Tooltip>
//             </HeaderIcons>
//             </div>
//         </HeaderContainer>
//     );
// };

// export default Header;



import styled from 'styled-components';
import { Avatar, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { ExitToApp } from '@material-ui/icons'; // Import a logout icon from Material-UI

// Full-width HeaderContainer
const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    
    width: 100%;
    height: 60px;
 background-color:#ADB8EB;
 
    border-bottom: 1px solid lightgray;
    color: white;
    justify-content: center; /* Center the content horizontally */
`;

// New container for internal content, with max-width 1140px
const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px; /* Maximum width of 1140px */
`;

const HeaderLogo = styled.div`
    display: flex;
    align-items: center;
    img {
        width: 80px;
    }
    @media (min-width: 1024px) {
        width: 200px; /* Size for extra large screens, if needed */
    }
    span {
        font-size: 22px;
       
        color: white;
    }
`;

const HeaderIcons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    span {
        display: flex;
        align-items: center;
        padding:10px;
        /* Increased spacing between email and profile avatar */
        color: black;
    }
    
    svg.MuiSvgIcon-root {
       /* Adjusted margin */
       padding:10px;
         color: black;
        cursor: pointer; /* Change cursor to pointer for clickable icons */
    }
`;

const Header = ({ photoURL, email, onSearch, onSignOut }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        onSearch(query); // Pass the search query up to the parent component
    };

    return (
        <HeaderContainer>
            <ContentContainer> 
                <HeaderLogo>
                    <img src="spanlog.png" alt="Span Drive" />
                </HeaderLogo>

                <HeaderIcons>
                    <span>{email}</span>
                    <Avatar src="avatar.jpeg"  />
                    <Tooltip title="Logout" arrow>
                        <span>
                            <ExitToApp onClick={onSignOut} /> {/* Logout icon */}
                        </span>
                    </Tooltip>
                </HeaderIcons>
            </ContentContainer>
        </HeaderContainer>
    );
};

export default Header;
