
import React, { useState } from 'react';
import { auth } from './firebase';
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  height: 100vh;
  width: 100vw;
 background: linear-gradient(180deg, #CED9F2, #FAC7C7, #CED9F2);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginWrapper = styled.div`
   background: linear-gradient(180deg, #CED9F2, #FAC7C7, #CED9F2);
  padding: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  border-radius: 20px; /* Corrected border-radius */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Added box-shadow */

  img {
    width: 150px; /* Increased logo size */
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  input {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  button {
    width: 90px;
  background: #e76f51;
    padding: 10px 20px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
    text-align: center; /* Center text horizontally */
    line-height: 20px;  /* Center text vertically */
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px auto 0 auto; /* Center button horizontally and add top margin */
    display: block; /* Ensure it's treated as a block-level element */
  }

  p {
    color: red;
    margin-top: 10px;
  }
`;

const SignIn = ({ onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check credentials (this part should be handled in auth, but you may want to keep it for testing)
    if (email === 'admin@gmail.com' && password === 'admin123') {
      auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          // Clear email and password fields after successful login
          setEmail('');
          setPassword('');
          onSignIn();
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      setError('Invalid email or password.');
    }
  };

  return (
    <BackgroundContainer>
      <LoginWrapper>
        <img src="spanlog.png" alt="Span Drive" />
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
          {error && <p>{error}</p>}
        </form>
      </LoginWrapper>
    </BackgroundContainer>
  );
};

export default SignIn;




// import React, { useState } from 'react';
// import { auth } from './firebase';
// import styled from 'styled-components';

// const BackgroundContainer = styled.div`
//   height: 100vh;
//   width: 100vw;
  
//   background-size: cover;
//   background-position: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
  
// `;
// const LoginWrapper = styled.div`
//   background: linear-gradient(180deg, #CED9F2, #FAC7C7, #CED9F2);
//   padding: 20px;
//   width: 400px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   border: 1px solid gray;
//   border-radius: 20px;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

//   img {
//     width: 150px;
//   }

//   form {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//   }

//   input {
//     margin: 10px 0;
//     padding: 10px;
//     border-radius: 5px;
//     border: 1px solid #ccc;
//   }

//   button {
//     width: 90px;
//     background: #e76f51;
//     padding: 10px 20px;
//     color: #fff;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     font-size: 13px;
//     text-align: center;
//     line-height: 20px;
//     border: 0;
//     outline: 0;
//     border-radius: 5px;
//     cursor: pointer;
//     margin: 20px auto 0 auto;
//     display: block;
//   }

//   p {
//     color: red;
//     margin-top: 10px;
//   }
// `;

// const SignIn = ({ onSignIn }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
    
//     // Admin credentials
//     const adminEmail = 'admin@gmail.com';
//     const adminPassword = 'admin123';
    
//     // Regular user credentials
//     const userEmail = 'user@gmail.com';
//     const userPassword = 'user123';

//     // Check credentials and login
//     auth.signInWithEmailAndPassword(email, password)
//       .then(() => {
//         if (email === adminEmail && password === adminPassword) {
//           alert('Admin login successful! You are logged in as Admin.');
//         } else if (email === userEmail && password === userPassword) {
//           alert('User login successful! You are logged in as User.');
//         } else {
//           alert('Invalid email or password');
//         }

//         setEmail('');
//         setPassword('');
//         onSignIn();
//       })
//       .catch((error) => {
//         setError(error.message);
//       });
//   };

//   return (
//     <BackgroundContainer>
//       <LoginWrapper>
//         <img src="spanlog.png" alt="Span Drive" />
//         <form onSubmit={handleSubmit}>
//           <input
//             type="email"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           <input
//             type="password"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//           <button type="submit">Login</button>
//           {error && <p>{error}</p>}
//         </form>
//       </LoginWrapper>
//     </BackgroundContainer>
//   );
// };

// export default SignIn;
